/* Global styles */
* {
	box-sizing: border-box; /* Ensures that padding and borders are included in the total width and height of elements, providing a more intuitive sizing model. */
}

/* Carousel container styles */
.carousel-container {
	position: relative; /* Establishes a positioning context for absolutely positioned child elements like '.carousel-item'. */
	height: 100%; /* Sets the height to occupy the full height of its parent container. */
	width: 100%; /* Sets the width to occupy the full width of its parent container. */
	min-height: 900px; /* Ensures the carousel has a minimum height of 900px. */
	perspective: 1000px; /* Provides a 3D space perspective, affecting the 3D transformed children. */
	transform-style: preserve-3d; /* Allows child elements to be positioned in 3D space. */
}

/* Individual carousel item styles */
.carousel-item {
	display: flex; /* Uses Flexbox layout, providing a more efficient way to lay out, align, and distribute space among items. */
	position: absolute; /* Positions each carousel item absolutely within the '.carousel-container'. */
	width: 100%; /* Each item takes the full width of the carousel container. */
	transition: all 0.75s ease; /* Smooth transition for all properties over 0.75 seconds with an 'ease' timing function. */
	top: calc(var(--offset) * 300px); /* Dynamically positions the item vertically based on a CSS variable '--offset'. */
	opacity: 1; /* Fully opaque by default. */

	/* Styles for the active carousel item */
	&[data-offset="0"] {
		transform: translateX(calc(var(--direction) * -100%)); /* Positions the active item based on the '--direction' variable. */
		animation: slideInFromLeft 1s forwards; /* Applies a sliding in animation from the left. */
	}

	/* Styles for hiding carousel items */
	&[data-offset="hidden"] {
		opacity: 0; /* Makes the item fully transparent, effectively hiding it. */
	}
}

/* Keyframes for 'slideInFromLeft' animation */
@keyframes slideInFromLeft {
	to {
		transform: translateX(0); /* Ends the animation by aligning the item with the start of the carousel container. */
	}
}

/* Keyframes for 'moveDown' animation */
@keyframes moveDown {
	to {
		transform: translateY(300px); /* Moves the item 300px down. */
		opacity: 1; /* Ensures the item is fully visible at the end of the animation. */
	}
}

/* Keyframes for 'moveDown2' animation */
@keyframes moveDown2 {
	to {
		transform: translateY(300px); /* Similar to 'moveDown', moves the item 300px down. */
		opacity: 1; /* Ensures the item is fully visible at the end of the animation. */
	}
}

/* Keyframes for 'slideOutToBottom' animation */
@keyframes slideOutToBottom {
	to {
		transform: translateY(300px); /* Moves the item 300px down. */
		opacity: 0; /* Transitions the item to be fully transparent, effectively hiding it. */
	}
}
